<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Báo cáo hoa hồng NHPK'">
          <template v-slot:preview>
            <!-- Header session -->
            <b-row class="mb-5">
              <b-col md="2">
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.start"
                  v-model="dpForm.startDate"
                ></date-picker>
              </b-col>
              <b-col md="2">
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.end"
                  v-model="dpForm.endDate"
                ></date-picker>
              </b-col>
              <b-col md="3">
                <b-button variant="primary" size="sm" @click="onClickListReport()">Tìm Kiếm</b-button>
              </b-col>
            </b-row>
            <!-- End of Header session -->

            <!-- Table List Report session -->
            <b-col md="12">
              <h4>1. Danh sách báo cáo</h4>
              <b-table
                :items="listReport"
                :fields="listReportFields"
                sort-by="count"
                class="table-bordered table-hover col-md-12"
                :busy="onLoading"
              >
                <template v-slot:table-busy>
                  <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
                </template>
                <template v-slot:cell(status)="row">
                  <span
                    v-if="checkStatus(row.item)"
                    v-text="row.item.statusName"
                    class="label font-weight-bold label-lg label-light-success label-inline"
                  ></span>
                  <span
                    v-else
                    v-text="row.item.statusName"
                    class="label font-weight-bold label-lg label-light-danger label-inline"
                  ></span>
                </template>
                <template v-slot:cell(createdAt)="row">
                  <p class="text-center">{{ row.item.createdAt }}</p>
                  <p class="text-center">{{ row.item.createdBy }}</p>
                </template>
                <template v-slot:cell(actions)="row">
                  <div class="d-flex justify-content-center">
                    <b-dropdown size="sm" id="dropdown-left" no-caret right>
                      <template slot="button-content">
                        <i
                          style="font-size: 1rem; padding-right: 0px"
                          class="flaticon2-settings"
                        ></i>
                      </template>
                      <!-- <b-dropdown-item @click="onClickViewDetail(row.item)">
                        <span style="color: #3f4254; font-size: 12px">
                          <i style="font-size: 1rem" class="flaticon-arrows"></i>
                          &nbsp; Xem
                        </span>
                      </b-dropdown-item> -->
                      <b-dropdown-item
                        @click="onClickExport(row.item)"
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem;"
                            class="flaticon2-download"
                          ></i>
                          &nbsp; Tải
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
            <!-- End of Table List Report session -->

            <!-- Table Detail Report -->
            <!-- <b-col md="12">
              <h4>2. Báo cáo chi tiết</h4>
              <b-container class="bv-example-row">
                <b-row>
                  <b-col md="10">
                  </b-col>
                  <b-col md="2">
                    <b-button class="text-right" variant="primary" size="sm" @click="onClickExport(reportDetail.rewardInfo)">Tải Báo Cáo</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <h5 class="text-center">{{ reportDetail.name }}</h5>
                  </b-col>
                </b-row>
                <b-row>
                  <p class="text-left">Gói thưởng toàn ngành hàng</p>
                </b-row>
                <b-row>
                  <b-col cols="4">
                  </b-col>
                  <b-col cols="4">
                    <p class="text-right">Lợi nhuận: {{  reportDetail.totalGrossProfit }}</p>
                  </b-col>
                  <b-col cols="4">
                    <p class="text-right">Mức thưởng trên lợi nhuận: {{ reportDetail.rewardPackagePoint }}</p>
                  </b-col>
                </b-row>
                <b-row>
                <b-table
                  :items="[reportDetail]"
                  :fields="targetReportDetailFields"
                  sort-by="count"
                  class="table-bordered table-hover col-md-12"
                  :busy="onLoading"
                >
                  <template v-slot:table-busy>
                    <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
                  </template>
                  <template v-slot:cell(targets)>
                    <p class="text-left">{{ 'Doanh thu' }}</p>
                    <p class="text-left">{{ 'Lợi nhuận' }}</p>
                    <p class="text-left">{{ 'Hạn mức tồn kho' }}</p>
                  </template>
                  <template v-slot:cell(targetRatios)="row">
                    <p class="text-center">{{ row.item.targetRatios.targetRevenue }}</p>
                    <p class="text-center">{{ row.item.targetRatios.targetNetIncome }}</p>
                    <p class="text-center">{{ row.item.targetRatios.targetInventory }}</p>
                  </template>
                </b-table>
                <p class="text-left">Mức thưởng theo từng nhân viên</p>
                <b-table
                  :items="reportDetail.employeeRewards"
                  :fields="employeeRewardFields"
                  sort-by="count"
                  class="table-bordered table-hover col-md-12"
                  :busy="onLoading"
                >
                  <template v-slot:table-busy>
                    <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
                  </template>
                  <template v-slot:cell(rewardPackage)="row">
                    <p class="text-center">{{ formatMoney(row.item.rewardPackage) }}</p>
                  </template>
                  <template v-slot:cell(targets)>
                    <p class="text-left">{{ 'Doanh thu' }}</p>
                    <p class="text-left">{{ 'Lợi nhuận' }}</p>
                    <p class="text-left">{{ 'Hạn mức tồn kho' }}</p>
                  </template>
                  <template v-slot:cell(targetRatios)="row">
                    <p class="text-center">{{ row.item.targetRatios.targetRevenue }}</p>
                    <p class="text-center">{{ row.item.targetRatios.targetNetIncome }}</p>
                    <p class="text-center">{{ row.item.targetRatios.targetInventory }}</p>
                  </template>
                  <template v-slot:cell(targetRatio)="row">
                    <p class="text-center">{{ row.item.targetRatio }}</p>
                  </template>
                  <template v-slot:cell(totalReward)="row">
                    <p class="text-center">{{ formatMoney(row.item.totalReward) }}</p>
                  </template>
                </b-table>
                </b-row>
              </b-container>
            </b-col> -->
            <!-- End of Table Detail Report -->
            
            
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import { BASE_URL, EXPORT_STOCK, LIMIT_BILL } from '../../../utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
// import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import localData from '../../../utils/saveDataToLocal';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      btnCreate: {
        fontWeight: '600!important',
      },
      stocks: [],
      search: '',
      searchStock: '',
      searchId: '',
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: EXPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'fullName',
          label: 'Tên nhân viên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
        },
        {
          key: 'jobTitleName',
          label: 'Chức vụ',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'rewardPoint',
          label: 'Điểm thưởng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '24%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      listItem: [],

      listReportFields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Chương trình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'actions',
          label: 'Hành Động',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        }
      ],
      listReport: [],

      targetReportDetailFields: [
        {
          key: 'targets',
          label: 'Tiêu chí',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'targetRatios',
          label: 'Tỉ lệ đạt tiêu chí',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'targetRatio',
          label: 'Tỉ lệ đạt tiêu chí',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'rewardPackage',
          label: 'Gói thưởng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'totalReward',
          label: 'Thưởng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
      ],
      targets: [
        { 
          key: 'targetRevenue',
          value: 'Doanh Thu'
        },
        { 
          key: 'targetNetIncome',
          value: 'Lợi Nhuận'
        },
        { 
          key: 'targetInventory',
          value: 'Hạn mức tồn kho'
        }
      ],
      employeeRewardFields: [
        {
          key: 'name',
          label: 'Nhân viên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'rewardRatio',
          label: 'Tỉ lệ gói thưởng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'rewardPackage',
          label: 'Gói thưởng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'targets',
          label: 'Tiêu chí',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'targetRatios',
          label: 'Tỉ lệ đạt tiêu chí',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
         {
          key: 'targetRatio',
          label: 'Tỉ lệ đạt tiêu chí',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'totalReward',
          label: 'Thưởng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
      ],
      reportDetail: {
        rewardInfo: {},
        name: '',
        totalGrossProfit: 0,
        rewardPackagePoint: 0,
        targetRatios: {
          targetRevenue: 0,
          targetNetIncome: 0,
          targetInventory: 0,
        },
        targetRatio: 0,
        rewardPackage: 0,
        totalReward: 0,
        employeeRewards: [
          {
            name: '',
            rewardRatio: 0,
            rewardPackage: 0,
            targetRatios: { 
              targetRevenue: 0,
              targetNetIncome: 0,
              targetInventory: 0,
            },
            targetRatio: 0,
            totalReward: 0
          }
        ]
      }
    };
  },
  methods: {
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    countBill: async function (fromDate, toDate) {
      let result = 0;
      ApiService.setHeader();
      await ApiService.get(
        `rewardAccessoryReport/count-bill?fromDate=${fromDate}&toDate=${toDate}`
      ).then(({ data }) => {
        result = data.data;
      });
      return result;
    },
    reportClick: async function () {
      let key = uuidv4();
      let totalPageBill = 1;
      let currentPage = 1;
      let searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (!searchFromDay) {
        alert('Bạn chưa chọn ngày bắt đầu!');
        return;
      }
      if (!searchToDay) {
        alert('Bạn chưa chọn ngày kết thúc!');
        return;
      }
      let billCount = await this.countBill(searchFromDay, searchToDay);
      totalPageBill = parseInt(billCount / LIMIT_BILL);
      if (billCount % LIMIT_BILL !== 0) totalPageBill++;

      for (var i = 0; i < totalPageBill; i++) {
        currentPage = parseInt(i) + 1;
        //console.log(`Key: ${key} ---- Page: ${currentPage}`);
        let result = await this.exportExcel(
          searchFromDay,
          searchToDay,
          'working',
          key,
          currentPage
        );
        if (!result) {
          return;
        }
        if (totalPageBill === currentPage) {
          await this.exportExcel(
            searchFromDay,
            searchToDay,
            'download',
            key,
            currentPage
          );
        }
      }
    },
    exportExcel: async function (fromDate, toDate, status, key, currentPage) {
      let baseUrl = BASE_URL;
      //baseUrl = 'http://localhost:3000/api/';
      let url = `${baseUrl}rewardAccessoryReport/export-excel?fromDate=${fromDate}&toDate=${toDate}&status=${status}&key=${key}&currentPage=${currentPage}`;
      let options = { url: url, method: 'POST' };
      if (status === 'download') {
        options.responseType = 'blob';
      }
      return await axios(options)
        .then((response) => {
          if (status === 'download') {
            let headerLine = response.headers['content-disposition'];
            let startFileNameIndex = headerLine.indexOf('"') + 1;
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(
              startFileNameIndex,
              endFileNameIndex
            );
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);

            fileLink.click();
          } else {
            return response;
          }
        })
        .catch(() => {
          this.makeToastFaile('Xuất Excel không thành công');
        });
    },
    fetchData: function () {
      this.onLoading = true;
      let searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      ApiService.setHeader();
      ApiService.get(
        `rewardAccessoryReport?fromDate=${searchFromDay}&toDate=${searchToDay}`
      ).then(({ data }) => {
        this.lis = [];
        data.data.forEach((item, index) => {
          let element = {
            count: index * 1 + 1,
            fullName: item.fullName,
            jobTitleName: item.jobTitleName,
            rewardPoint: item.rewardPoint,
          };
          this.listItem.push(element);
        });
        this.totalPages = 2;
        this.onLoading = false;
      });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },

    // test
    checkStatus: function (item) {
      if (item.status === 1) {
        return true;
      } else {
        return false;
      }
    },
    onClickListReport: async function (options) {
      options = options? options : {};
      this.onLoading = true;
      const searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      const searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (!options.isCreated && !searchFromDay) {
        alert('Bạn chưa chọn ngày bắt đầu!');
        return;
      }
      if (!options.isCreated && !searchToDay) {
        alert('Bạn chưa chọn ngày kết thúc!');
        return;
      }

      ApiService.setHeader();
      const { data } = await ApiService.get(`rewardProgram?page=1&limit=10&name=&rewardType=2&fromDate=${searchFromDay}&toDate=${searchToDay}`)

      this.listReport = data.data.data.map((item, index) => ({...item, count: index + 1}));
      this.onLoading = false;
    },
    onClickExport: async function (item) {
      const baseUrl = BASE_URL;
      const rewardProgramId = item.id;
      const url = `${baseUrl}rewardAccessoryReport/${rewardProgramId}/export-excel`;
      ApiService.setHeader();
      const options = { url: url, method: 'POST', responseType: 'blob' };
      return axios(options).then((response) => {
          const headerLine = response.headers['content-disposition'];
          const startFileNameIndex = headerLine.indexOf('"') + 1;
          const endFileNameIndex = headerLine.lastIndexOf('"');
          const filename = headerLine.substring(
            startFileNameIndex,
            endFileNameIndex
          );
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);

          fileLink.click();
          this.onLoading = false;
        })
        .catch(() => {
          this.makeToastFaile('Xuất Excel không thành công');
        });
    },
    onClickViewDetail: async function (item) {
      const baseUrl = BASE_URL;
      const rewardProgramId = item.id;
      const url = `${baseUrl}rewardAccessoryReport/${rewardProgramId}`;
      ApiService.setHeader();
      const options = { url: url, method: 'GET' };
      return axios(options).then((response) => {
          const data = response.data.data;
          this.reportDetail = {
            rewardInfo: item,
            name: item.name,
            totalGrossProfit: data.totalGrossProfit || 0,
            rewardPackagePoint: data.rewardPackagePoint || 0, 
            targetRatios: {
              targetRevenue: data.rateIncome || 0,
              targetNetIncome: data.rateGrossProfit || 0,
              targetInventory: data.totalRateInventory || 0,
            },
            targetRatio: data.avgRate,
            rewardPackage: data.rewardPointTarget,
            totalReward: data.totalRewardPoint,
            employeeRewards: (data.listItem || []).map((employee) => ({
              name: employee.fullName,
              rewardRatio: employee.rewardPackagePointRate,
              rewardPackage: employee.rewardPackagePoint,
              targetRatios: { 
                targetRevenue: employee.rateRevenue,
                targetNetIncome: employee.rateGrossProfit,
                targetInventory: employee.rateInventory,
              },
              targetRatio: employee.rateComplete,
              totalReward: employee.rewardPoint
            }))
          }

          this.onLoading = false;
        })
        .catch(() => {
          this.makeToastFaile(`Xem báo cáo ${item.name} không thành công`);
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo hoa hồng NHPK', route: 'overview' },
      { title: 'Danh sách báo cáo hoa hồng NHPK' },
    ]);
  },
  created() {
    this.onClickListReport({ isCreated: true })
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
  },
};
</script>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.productCode:hover {
  text-decoration: underline;
}
</style>
